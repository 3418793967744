<template>
  <div class="chart">
    <div id="chartbox2"></div>
  </div>
</template>
<script>
import { getLocalStore } from "@/util/store";
export default {
  props: {
    chartData: {
      type: Object,
      default: () => {return {}},
    },
  },
  data() {
    return {
      calculateData:{}
    };
  },
  watch:{
        chartData(){
            this.renderPriceEcharts();
        }
       
  },
  created(){
    setTimeout(() => {
      this.renderPriceEcharts();
    }, 500);
    
  },
   mounted(){
    this.calculateData = getLocalStore('calculateData');
  },
  
  methods: {
    //加载历年成交价格分析图表
    renderPriceEcharts(){
      let myChart = this.$echarts.init(document.getElementById('chartbox2'));
      let option;
      option = {
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     type: 'cross',
        //     crossStyle: {
        //       color: '#999'
        //     }
        //   }
        // },
        grid:{
          right:60,
        },
        legend: {
          icon: "circle",
          data: ['交易总金额', '成交均价']
        },
        xAxis: [
          {
            type: 'category',
            data: this.chartData.years,
            axisPointer: {
              type: 'shadow'
            },
            axisTick:{
              length:0
            },
           
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '亿元',
            axisLabel: {
              formatter: '？？'
            }
          },
          {
            type: 'value',
            name: '元/平',
            axisLabel: {
              formatter: '？？'
            }
          }
        ],
        series: [
          {
            name: '交易总金额',
            type: 'bar',
            data: this.chartData.prices,
            barGap:1,
          },
         
          {
            name: '成交均价',
            type: 'line',
            yAxisIndex: 1,
            data: this.chartData.avgs
          }
        
        ]
      };

      if(this.calculateData.view=='no'){
        myChart.setOption(option);
      }else{
        option.yAxis=[
          {
            type: 'value',
            name: '亿元',
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: '元/平',
            axisLabel: {
              formatter: '{value}'
            }
          }
        ]
        option.tooltip={
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        }
        myChart.setOption(option);
      }

    },
    
  },
};
</script>
<style lang="less" scoped>
.chart{
  height: 260px;
  width: 100%;
}

#chartbox2{
  width: 100%;
  height: 260px;
  padding-top: 10px;
}
</style>