<template>
  <div class="mapContainer">
    <div id="mapDemo1"></div>
  </div>
</template>
<script>
import { getLocalStore } from "@/util/store";
let pinyin = require("js-pinyin");
export default {
  props: {
    areaData:[]
  },
  data() {
    return {
      chartData: {},
      // areaData:[
      //   {
      //     name: '泰山区',
      //     value: 52
      //   },
      //   {
      //     name: '岱岳区',
      //     value: 23
      //   },
      //   {
      //     name: '宁阳县',
      //     value: 9
      //   },
      //   {
      //     name: '东平县',
      //     value: 16
      //   },
      //   {
      //     name: '新泰市',
      //     value: 30
      //   },
      //   {
      //     name: '肥城市',
      //     value: 26
      //   },
      // ],
      // geoCoordMapArea:{
      //   '泰山区': [117.13, 36.18],
      //   '岱岳区': [117, 36.18],
      //   '宁阳县': [116.80, 35.77],
      //   '东平县': [116.47, 35.93],
      //   '新泰市': [117.77, 35.92],
      //   '肥城市': [116.77, 36.18],
      // },
      center: [],
      cityName: "",
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.paintMap1();
  },

  methods: {
    paintMap1() {
      var mapChart = this.$echarts.init(document.getElementById("mapDemo1"));
      //拼接数组业务数据和坐标数据，给scatter和effectScatter(地图上表的点)标点使用；给tooltip悬浮显示数据使用
      var convertData = function (businessData, geocoordData) {
        //全部项目+重点项目，需要传入业务数据
        var res = [];
        for (var i = 0; i < businessData.length; i++) {
          var geoCoord = geocoordData[businessData[i].name];
          if (geoCoord) {
            res.push({
              name: businessData[i].name,
              value: geoCoord.concat(businessData[i].value), //value包含坐标和业务数据
            });
          }
        }
        return res;
      };
      //默认泰安市
      var areaData = this.areaData;
      var code = ''
      if(getLocalStore("cityAlibabaCode")){
        code = getLocalStore("cityAlibabaCode")
      }else{
        code = '370900'
      }
      //先获取城市数据
      this.axios
        .request({
          method: "get",
          url:
            "/dms/common/getGEOByCode/" + code
        })
        .then((res) => {
          //遍历区级信息,获取圆圈定位点
          var geoCoordMapArea = {};
          res.data.data.features.forEach(function (val, i) {
            var countyName = val.properties.name;
            var countyDot = val.properties.center;
            geoCoordMapArea[countyName] = countyDot;
          });
          this.geoCoordMapArea = geoCoordMapArea;
          //获取城市拼音
          if (getLocalStore("cityAlibaba") != "") {
            this.cityName = pinyin
              .getFullChars(getLocalStore("cityAlibaba"))
              .toLowerCase();
          } else {
            this.cityName = "taianshi";
          }
          //地图配置
          var option = {
            geo: {
              type: "map",
              map: this.cityName,
              roam: true, //允许缩放和平移
              zoom: 1.1, //地图缩放多少倍
              label: {
                normal: {
                  show: false,
                  color: "#fff",
                },
                emphasis: {
                  show: false,
                  color: "#666",
                },
              },
              itemStyle: {
                //地图区域的多边形图形样式
                normal: {
                  areaColor: "#ffffff", //地图区域颜色
                  borderColor: "#333333", //图形的描边颜色
                  borderWidth: 1, //描边线宽。为 0 时无描边
                  borderType: "solid",
                  opacity: 0.6,
                },
                emphasis: {
                  areaColor: "rgba(47,79,79, .1)",
                },
              },
            },
          };
          //画地图
          this.$echarts.registerMap(this.cityName, res.data.data);
          if (getLocalStore("calculateData").view == "yes") {
            option.series = [
              {
                name: "",
                type: "scatter",
                coordinateSystem: "geo",
                symbol: "circle", //设置图形 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'
                symbolSize: function (val) {
                  return val[2]/15;
                },
                data: convertData(this.areaData, this.geoCoordMapArea),
                label: {
                  formatter: "{b}",
                  position: "right",
                  show: false,
                },
                itemStyle: {
                  normal: {
                    color: "#80A6FF", //标志颜色
                  },
                },
                emphasis: {
                  label: {
                    show: false,
                  },
                },
              },
            ];
            option.tooltip = {
              //提示框组件
              show: true,
              borderColor: "#fff",
              formatter: function (item) {
                var num = 0;
                areaData.forEach(function (res, i) {
                  if (item.name == res.name) {
                    num = res.value;
                  }
                });
                var dom =
                  '<div><p style="margin-bottom:9px">' +
                  item.name +
                  '</p><p>共<span style="color: #185BCE;">' +
                  num +
                  "</span>个清算项目</p></div>";
                return dom;
              },
            };
            mapChart.setOption(option);
          } else {
            option.tooltip = {
              //提示框组件
              show: true,
              borderColor: "#fff",
              formatter: function (item) {
                var dom = "<div>" + item.name + "</div>";
                return dom;
              },
            };
            mapChart.setOption(option);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.mapContainer {
  width: 100%;
  height: 100%;
}
#mapDemo1 {
  width: 543px;
  height: 544px;
}
</style>