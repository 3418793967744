<template>
  <div class="chart">
    <div id="chartbox"></div>
  </div>
</template>
<script>
import { getLocalStore } from "@/util/store";
export default {
  props: {
    chartData: {
      type: Object,
      default: () => {return {}},
    },
  },
  data() {
    return {
      calculateData:{}
    };
  },
  watch:{
        chartData(){
            this.renderEcharts();
        }
       
  },
  created(){
    setTimeout(() => {
      this.renderEcharts();
    }, 500);
    
  },
  mounted(){
    this.calculateData = getLocalStore('calculateData');
  },
  
  methods: {
    //加载历年土地交易信息图表
    renderEcharts(){
      let myChart = this.$echarts.init(document.getElementById('chartbox'));
      let option;
      option = {
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     type: 'cross',
        //     crossStyle: {
        //       color: '#999'
        //     }
        //   }
        // },
        legend: {
          icon: "circle",
          data: ['土地成交面积', '土地成交宗数']
        },
        xAxis: [
          {
            type: 'category',
            data: this.chartData.years,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '公顷',
            axisLabel: {
              formatter: '？？'
            }
          },
          {
            type: 'value',
            name: '数量',
            axisLabel: {
              formatter: '？？'
            }
          }
        ],
        series: [
          {
            name: '土地成交面积',
            type: 'bar',
            data: this.chartData.areas,
          },
         
          {
            name: '土地成交宗数',
            type: 'line',
            yAxisIndex: 1,
            data: this.chartData.lands
          }
        
        ]
      };
      if(this.calculateData.view=='no'){
        myChart.setOption(option);
      }else{
        option.yAxis=[
          {
            type: 'value',
            name: '公顷',
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: '数量',
            axisLabel: {
              formatter: '{value}'
            }
          }
        ]
        option.tooltip={
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        }
        myChart.setOption(option);
      }

      
    },
 
    
  },
};
</script>
<style lang="less" scoped>
.chart{
  height: 260px;
  width: 100%;
}

#chartbox{
  width: 100%;
  height: 260px;
  padding-top: 10px;
}
</style>