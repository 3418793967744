<template>
  <div class="mapContainer">
    <div id="mapDemo1"></div>
  </div>
</template>
<script>
import { getLocalStore } from "@/util/store";
let pinyin = require("js-pinyin");
export default {
  props: {},
  data() {
    return {
      cityName: "",
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.paintMap1();
  },

  methods: {
    paintMap1() {
      var mapChart = this.$echarts.init(document.getElementById("mapDemo1"));
      //默认泰安市
      var code = "";
      if (getLocalStore("countyAlibabaCode")) {
        code = getLocalStore("countyAlibabaCode");
      } else {
        code = "370902";
      }
      //先获取城市数据
      this.axios
        .request({
          method: "get",
          url: "/dms/common/getGEOByCode/" + code,
        })
        .then((res) => {
          //获取城市拼音
          if (getLocalStore("countyAlibaba") != "") {
            this.cityName = pinyin
              .getFullChars(getLocalStore("countyAlibaba"))
              .toLowerCase();
          } else {
            this.cityName = "taishanqu";
          }
          console.log(this.cityName)
          //地图配置
          var option = {
            tooltip: {
              //提示框组件
              show: true,
              trigger: "item",
            },
            geo: {
              type: "map",
              map: this.cityName,
              roam: true, //允许缩放和平移
              //初始化时的地图位置，可通过改变地图中心视角的经纬度来实现地图的平移
              zoom: 1, //地图缩放多少倍
              tooltip: {
                //提示框组件
                show: true,
                trigger: "item",
              },
              label: {
                //设置地图区域名的文本样式，例如地名的字体大小等
                normal: {
                  show: false,
                  color: "#fff",
                },
                emphasis: {
                  show: false,
                  color: "#666",
                },
              },
              itemStyle: {
                //地图区域的多边形图形样式
                normal: {
                  areaColor: "#ffffff", //地图区域颜色
                  borderColor: "#333333", //图形的描边颜色
                  borderWidth: 1, //描边线宽。为 0 时无描边
                  borderType: "solid",
                  opacity: 0.6,
                },
                emphasis: {
                  areaColor: "rgba(47,79,79, .1)",
                },
              },
            },
          };
          //画地图
          this.$echarts.registerMap(this.cityName, res.data.data);
          mapChart.setOption(option);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.mapContainer {
  width: 100%;
  height: 100%;
}
#mapDemo1 {
  width: 543px;
  height: 544px;
}
</style>