<template>
  <div class="base-container">
    <div class="main-wrapper">
      <div class="top_box">
          <div class="title area">{{ this.calculateData.province || "山东省" }} {{ this.calculateData.city || "泰安市" }} {{ this.calculateData.county || "" }}</div>
          <div class="btn_box">
            <div class="set_btn" @click="toSetData">设置测算参数</div>
            <div class="down_btn" @click="downReport">下载测算报告</div>
            <div class="look_btn" @click="lookReport">查看报告示例</div>
            <div class="look_btn" @click="openHistory">我的订单({{historyNum}})</div>
          </div>
      </div>
      <div class="left_box">
        <div class="card_box">
          <div class="card_item">
            <p class="ci_tit">总地块</p>
            <p class="ci_txt">{{ chartData.cardValue.landNum || "-" }}</p>
          </div>
          <div class="card_item">
            <p class="ci_tit">房地产收入总额</p>
            <p class="ci_txt">
              {{ chartData.cardValue.totalRevenue || "-" }}<span>万元</span>
            </p>
          </div>
          <div class="card_item">
            <p class="ci_tit">平均地价</p>
            <p class="ci_txt">
              {{ chartData.cardValue.avgLandPrice || "-" }}<span>元/平</span>
            </p>
          </div>
          <div class="card_item">
            <p class="ci_tit">销售均价</p>
            <p class="ci_txt">
              {{ chartData.cardValue.avgSalePrice || "-" }}<span>元/平</span>
            </p>
          </div>
          <div class="card_item">
            <p class="ci_tit">应补缴土地增值税</p>
            <p class="ci_txt">
              {{ chartData.cardValue.tax || "-" }}<span>万元</span>
            </p>
          </div>
        </div>
        <div class="map_box">
          
          <div class="map_content">
            <mapChart :areaData="areaData" v-if="isChart && isCity"></mapChart>
            <countyChart v-if="isChart && !isCity"></countyChart>
          </div>
        </div>
      </div>
      <div class="right_box">
        <div class="chart_box">
          <div class="title">历年土地交易分析</div>
          <landChart v-if="isChart" :chartData="chartData"></landChart>
        </div>
        <div class="chart_box">
          <div class="title">历年成交价格分析</div>
          <historyPrice v-if="isChart" :chartData="chartData"></historyPrice>
        </div>
      </div>
    </div>
    <el-dialog
      title="我的订单"
      :visible.sync="showDown"
      @close="closeHistory"
      width="840px"
    >
      <div class="dialog">
        <div class="down_box">
          <img
            v-if="!showHistory"
            src="../../assets/img/nullRelation.png"
            alt=""
          />
          <el-table
            v-if="showHistory"
            ref="table"
            :span-method="objectSpanMethod"
            :data="tableData"
            border
            style="width: 100%; margin-top: 16px"
            height="280px"
            stripe
            :header-cell-style="{ background: '#F6F6F6', color: '#333333' }"
          >
            <el-table-column
              prop="serialNum"
              label="序号"
              width="50"
              align="center"
            ></el-table-column>

            <el-table-column prop="orderNum" label="订单编号" width="200">
            </el-table-column>
            <!-- <el-table-column prop="reportVersion" label="版本号" width="80">
            </el-table-column> -->
            <!-- <el-table-column prop="province" label="地址">
            </el-table-column> -->
            <el-table-column prop="province" label="地址">
              <template slot-scope="scope">
                <span>{{
                  scope.row.province +
                  "-" +
                  scope.row.city +
                  "-" +
                  scope.row.county
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="versionCreateDate" label="日期"></el-table-column>
            <el-table-column label="操作" align="center" width="130">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  @click="lookHistory(scope.row)"
                  >查看
                </el-button>
                <el-button
                  size="mini"
                  type="text"
                  @click="downChoseReport(scope.row)"
                  >下载
                </el-button>
                <el-button
                  size="mini"
                  type="text"
                  @click="deleteReport(scope.row)"
                  class="delete"
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <div class="feed_box" v-show="isPay">
      <div class="feed_card">
        <div class="feed_header">
          <div class="feed_title">下载精准测算报告</div>
          <div class="el-icon-close feed_close" @click="close()"></div>
        </div>
        <div class="feed_form">
          <i class="el-icon-info"></i>
          <div>即将前往协同财税网付款</div>
          <p>
            下载本次区域测算报告需要到协同税网进行付费，付费后对本区域测算次数以及报告下载没有限制。更换区域进行测算需要再次付费。
          </p>
        </div>
        <div class="feed_btn">
          <div class="feed_submit" @click="toPay()">确定</div>
        </div>
      </div>
    </div>
    <div class="feed_box" v-show="isSearchPay">
      <div class="feed_card">
        <div class="feed_header">
          <div class="feed_title">下载精准测算报告</div>
          <div class="el-icon-close feed_close" @click="closePay()"></div>
        </div>
        <div class="feed_form">
          <i class="el-icon-question"></i>
          <div>是否支付完成？</div>
        </div>
        <div class="feed_btn">
          <div class="feed_submit" @click="searchPay()">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStore, setStore, getLocalStore, setLocalStore } from "@/util/store";
import { mapMutations } from "vuex";
import fileDownload from "js-file-download";
import mapChart from "@/views/industryAnalysis/mapChart.vue";
import countyChart from "@/views/industryAnalysis/countyChart.vue";
import landChart from "@/views/industryAnalysis/landChart.vue";
import historyPrice from "@/views/industryAnalysis/historyPrice.vue";
import {Loading} from "element-ui";
export default {
  components: {
    mapChart: mapChart,
    countyChart: countyChart,
    landChart: landChart,
    historyPrice: historyPrice,
  },
  data() {
    return {
      userId: "",
      userSource: "",
      showDown: false,
      showHistory: false,
      chartData: {
        lands: [27, 19, 16, 25, 31, 10, 9, 19, 12, 10],
        areas: [
          42.4972, 30.8167, 28.7547, 75.3909, 69.9685, 14.4505, 27.8092,
          52.1945, 52.663174999999995, 23.773721000000002,
        ],
        prices: [
          32310.933, 19787.7693, 33397.3243, 86525.8639, 118198.3051,
          18472.0545, 44261.7235, 101440.414, 179455.2483, 75967.704,
        ],
        avgs: [
          1196.7, 1041.46, 2087.33, 3461.03, 3812.85, 1847.21, 4917.97, 5338.97,
          14954.6, 7596.77,
        ],
        years: [
          "2010",
          "2011",
          "2012",
          "2013",
          "2014",
          "2015",
          "2016",
          "2017",
          "2018",
          "2019",
        ],
        cardValue: {
          landNum: "2 ？？",
          totalRevenue: "?,?94,907",
          avgLandPrice: "?14.23",
          avgSalePrice: "?,338",
          tax: "?,338",
        },
        isDemo: true,
      },
      spanArr: {},
      calculateData: {},
      isPay: false,
      isChart: false,
      isSearchPay: false,
      isCity: false,
      areaData: [],
      tableData:[],
      scopeType:'',
      historyNum:0,
    };
  },
  created() {
    this.userSource = getLocalStore("userinfo").userSource;
    this.userId = getLocalStore("userinfo").id;
  },
  mounted() {
    //获取初始信息
    this.getUserLastOrderVersion();
    //获取下载历史
    this.downloadHistory(); //获取下载历史数据

   
  },

  methods: {
    ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      var spanArr = this.spanArr;
      switch (columnIndex) {
        case 1:
          return {
            rowspan: spanArr.orderNum.spanArr[rowIndex],
            colspan: spanArr.orderNum.spanArr[rowIndex] == 0 ? 0 : 1,
          };
          // eslint-disable-next-line no-unreachable
          break;
       
        default:
          return {
            rowspan: 1,
            colspan: 1,
          };
          // eslint-disable-next-line no-unreachable
          break;
      }
    },
    close() {
      this.isPay = false;
    },
    //展现点击上报
    getReport(recordType, modelName, clickName) {
      this.axios
        .request({
          method: "post",
          url: "/dms/saveUserBehaviorRecord",
          data: {
            pageName: "industryAnalysis",
            userName: getLocalStore("areainfo").userName,
            recordType: recordType,
            modelName: modelName,
            clickName: clickName,
            userId: getLocalStore("areainfo").id,
            ipAddress: getLocalStore("areainfo").ipAddress,
            userAddress: getLocalStore("areainfo").userAddress,
            ubr: getStore("reportobj") || {},
          },
        })
        .then((res) => {
          setStore("reportobj", res.data.data);
        });
    },

    //获取测算初始信息
    getUserLastOrderVersion(data) {
      let getProject_login = Loading.service({background: 'rgba(0, 0, 0, 0.5)'});
      var $orderNum = '';
      var $reportVersion = '';
      try {
        if(getLocalStore('orderNum')){
          $orderNum = getLocalStore('orderNum');
        }
        if(getLocalStore('reportVersion')){
          $reportVersion = getLocalStore('reportVersion');
        }
      } catch (error) {
        
      }
      
      if(data){
        $orderNum = data.orderNum;
        $reportVersion = data.reportVersion;
      }
      this.axios
        .request({
          loading: true,
          method: "post",
          url: "/dms/calculate/getUserLastOrderVersion",
          data: {
            province: "",
            provinceCode: "",
            city: "",
            cityCode: "",
            county: "",
            countyCode: "",
            delFlag: "",
            id: "",
            payFlag: "",
            orderNum: $orderNum,
            reportVersion: $reportVersion,
            userId: this.userId,
            userSource: this.userSource,
          },
        })
        .then((res) => {
          getProject_login.close();
          this.calculateData = res.data.data;
          setLocalStore("calculateData", res.data.data);
          setLocalStore("orderNum", res.data.data.orderNum);
          setLocalStore("reportVersion", res.data.data.version);
          this.scopeType = res.data.data.scope;
          if(this.calculateData.countyCode.length>0){
            this.getCityName(this.calculateData.countyCode)
          }else{
            this.getCityName(this.calculateData.cityCode)
          }
          //是否付费
          if (res.data.data.view == "yes") {
            this.isChart = false;
            //显示真实数据
            this.chartData.cardValue.landNum = res.data.data.viewValue.landNum;
            this.chartData.cardValue.totalRevenue =
              res.data.data.viewValue.totalIncome;
            this.chartData.cardValue.avgLandPrice =
              res.data.data.viewValue.avgLandPrice;
            this.chartData.cardValue.avgSalePrice =
              res.data.data.viewValue.avgSalePrice;
            this.chartData.cardValue.tax =
              res.data.data.viewValue.shouldPaySpeculate;
            this.chartData.lands = res.data.data.viewValue.landReport.lands;
            this.chartData.areas = res.data.data.viewValue.landReport.areas;
            this.chartData.prices = res.data.data.viewValue.landReport.prices;
            this.chartData.avgs = res.data.data.viewValue.landReport.avgs;
            this.chartData.years = res.data.data.viewValue.landReport.years;
            this.areaData = res.data.data.viewValue.landNums;
          }
          var $that = this;
          setTimeout(() => {
            $that.isChart = true;
          }, 300);
          this.showDown = false;
        });
    },
    //通过code获取省市区名称
    getCityName(code){
      this.axios
        .request({
          loading: true,
          method: "post",
          url: "/dms/calculate/getSynthesisAreaByCode",
          params: {
            code:code
          },
        })
        .then((res) => {
          var $data = res.data.data;
          this.province = $data.provinceCalculate;
          this.province_code = $data.provinceCalculateCode;
          this.city = $data.cityCalculate;
          this.city_code = $data.cityCalculateCode;
          setLocalStore("cityAlibaba",  $data.cityAlibaba);
          setLocalStore("cityAlibabaCode",  $data.cityAlibabaCode);

          if($data.type=='county'){
            this.county = $data.countyCalculate;
            this.county_code = $data.countyCalculateCode;
            setLocalStore("countyAlibaba",  $data.countyAlibaba);
            setLocalStore("countyAlibabaCode",  $data.countyAlibabaCode);
          }
          
          if (this.scopeType == "city") {
            this.isCity = true;
          } else {
            this.isCity = false;
          }
         
        });
    },

    //设置参数
    toSetData() {
      this.addMenuList("/setData");
      setStore("currentMenu", "/setData");
      this.$router.push({ path: "/setData" });
    },
    //查看下载历史数据
    downloadHistory() {
      this.axios
        .request({
          loading: true,
          method: "post",
          url: "/dms/calculate/getHistoryOrderVersionByUser",
          params: {
            userId: getLocalStore("userinfo").id,
            userSource: getLocalStore("userinfo").userSource,
          },
        })
        .then((res) => {
          this.historyNum = res.data.data.length;
          if (res.data.data.length > 0) {
            this.tableData = res.data.data;
             //合并单元格
            const megre = ["province", "orderNum"];
            const data = this.tableData;
            const spanArr = {};
            megre.forEach((m) => {
              spanArr[m] = { spanArr: [], pos: 0 };
            });
            data.forEach((row, i) => {
              megre.forEach((m) => {
                if (i == 0) {
                  spanArr[m].spanArr.push(1);
                  spanArr[m].pos = 0;
                } else {
                  if (row[m] === data[i - 1][m]) {
                    spanArr[m].spanArr[spanArr[m].pos] += 1;
                    spanArr[m].spanArr.push(0);
                  } else {
                    // 不相等push 1,并且可修改下标指向
                    spanArr[m].spanArr.push(1);
                    spanArr[m].pos = i;
                  }
                }
              });
            });
            this.spanArr = spanArr;
            this.showHistory = true;

          }else{
            this.tableData = [];
          }
        });
    },
    //打开下载历史
    openHistory() {
      this.showDown = true;
    },
    //关闭下载历史
    closeHistory() {
      this.showDown = false;
    },
    //查看报告示例
    lookReport() {
      if (this.calculateData.view != "yes") {
        this.isPay = true;
      } else {
        this.axios
          .request({
            loading: true,
            method: "post",
            url: "/dms/calculate/reportCalculateFile",
            params: {
              orderNum: getLocalStore("orderNum"),
              reportVersion: getLocalStore("reportVersion"),
              userId: getLocalStore("userinfo").id,
              userSource: this.userSource,
            },
          })
          .then((res) => {
            setStore("reportData", res.data.data);
            this.$router.push({ path: "/report" });
          });
      }
    },
    //下载报告
    downReport() {
      if (this.calculateData.view != "yes") {
        this.isPay = true;
      } else {
        this.getReport(2, "report", "downloadReport");
        this.axios
          .request({
            loading: true,
            method: "post",
            url: "/dms/calculate/downloadCalculateFile",
            params: {
              orderNum: getLocalStore("orderNum"),
              reportVersion: getLocalStore("reportVersion"),
            },
          })
          .then((res) => {
            if (res.data.data.tip == "none") {
              if (res.data.data.downUrl) {
                //打开下载链接
                window.open("https://" + res.data.data.downUrl, "_blank");
              } else {
                this.$message.error("下载地址不存在，请重新测算！");
              }
            } else {
              this.$message.error("请先进行设置自定义测算参数！");
            }
          });
      }
    },
    //查看历史报告
    lookHistory(res) {
      this.getUserLastOrderVersion(res)
    },
    //下载指定报告
    downChoseReport(res) {
      window.open("https://" + res.downUrl, "_blank");
    },
    //删除指定下载历史
    deleteReport(res) {
      this.axios
        .request({
          loading: true,
          method: "post",
          url: "/dms/calculate/deleteDownloadHistory",
          params: {
            orderNum: res.orderNum,
            reportVersion: res.reportVersion,
          },
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.$message.success("删除成功！");
            this.downloadHistory();
          }
        });
    },
    toPay() {
      window.open(
        "http://www.xt12366.com/index.php/Home/Yunce/pay?userid=" +
          getLocalStore("userinfo").id +
          "&buyid=" +
          getLocalStore("orderNum") +
          "&userSource=" +
          this.userSource,
        "_blank"
      );
      this.isPay = false;
      this.isSearchPay = true;
    },
    //查询订单是否支付
    searchPay() {
      this.axios
        .request({
          loading: true,
          method: "post",
          url: "/dms/calculate/checkPayStatus",
          params: {
            orderNum: getLocalStore("orderNum"),
          },
        })
        .then((res) => {
          //成功的情况下
          if (res.data.data.view == "yes") {
            this.isSearchPay = false;
            this.$message.success(res.data.data.msg);
            //重新加载数据
            this.getUserLastOrderVersion();
          } else {
            this.$message.error(res.data.data.msg);
          }
        });
    },
    closePay() {
      this.isSearchPay = false;
    },
  },
};
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  width: 0;
}
.tag {
  color: #969799;
  margin: 16px 0;
  position: relative;
  margin-bottom: 40px;
}
.top_box{
  width: 100%;
  height: 32px;
  margin-top: 40px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .area{
    margin-top: 8px;
    margin-left: 0;
  }
  .btn_box {
      height: 32px;
      width: 459px;
      display: flex;
      justify-content: space-between;
      div {
        height: 32px;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
      }
      .set_btn {
        width: 108px;
        background: #185bce;
        border-radius: 2px;
        color: #ffffff;
      }
      .down_btn {
        width: 108px;
        background: #185bce;
        border-radius: 2px;
        color: #ffffff;
      }
      .look_btn {
        width: 108px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #e3e3e3;
        color: #333333;
        box-sizing: border-box;
      }
      .look_btn:last-child{
        margin-right: 0
      }
      .history_btn {
        width: 112px;
        color: #185bce;
      }
    }
}
.backbtn {
  position: absolute;
  right: 0;
  top: -10px;
}
.left_box {
  width: 741px;
  float: left;
  .card_box {
    width: 160px;
    float: left;
    padding-top: 8px;
    .card_item {
      width: 155px;
      height: 86px;
      background: #ffffff;
      box-shadow: 0px 2px 17px 0px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      margin-bottom: 23px;
      .ci_tit {
        font-size: 14px;
        color: #999999;
        padding-top: 13px;
        margin-bottom: 12px;
        text-align: center;
      }
      .ci_txt {
        font-size: 18px;
        color: #185bce;
        text-align: center;
        span {
          font-size: 12px;
          color: #333333;
          line-height: 14px;
          margin-left: 4px;
        }
      }
    }
  }
  .map_box {
    width: 570px;
    float: left;
    
    .map_content {
      width: 523px;
      height: 524px;
      margin-left: 15px;
      background: url("../../assets/img/map_bg.png");
    }
  }
}
.right_box {
  width: 459px;
  float: right;
  .chart_box {
    width: 459px;
    height: 286px;
    background: #ffffff;
    box-shadow: 0px 2px 17px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-bottom: 24px;
    padding: 16px 0 0 0;
    box-sizing: border-box;
  }
}
.title {
  height: 16px;
  padding-left: 12px;
  margin-left: 16px;
  border-left: 4px solid #185bce;
  line-height: 16px;
  color: #333333;
  margin-bottom: 2px;
}
.pro_tit {
  padding-left: 16px;
  margin-left: 16px;
  margin-bottom: 2px;
}
.mbot {
  margin-bottom: 16px;
}
.dialog {
  width: 100%;
  height: 347px;
  border-top: 1px solid #ebedf0;
  .next_box {
    overflow: hidden;
    display: flex;
    justify-content: center;
    .next_btn {
      display: flex;
      width: 60px;
      height: 32px;
      line-height: 32px;
      justify-content: center;
      color: #ffffff;
      background: #185bce;
      cursor: pointer;
    }
  }
  .down_box {
    width: 807px;
    height: 291px;
    margin: 0 auto;
    padding: 0;

    img {
      display: block;
      margin: 20px auto 0;
    }
    .delete {
      color: #d40000 !important;
    }
    .el-table thead {
      height: 35px;
    }
    .el-table /deep/.el-table__cell {
      padding: 3px 0 !important;
    }
    .el-table .cell {
      line-height: 17px;
    }
    /deep/.el-table__row .el-table__cell {
      background: #ffffff !important;
    }
  }
}
/deep/.el-dialog__body {
  padding: 0 !important;
}
/deep/.el-dialog__header {
  padding-bottom: 20px !important;
}
.el-table--enable-row-hover /deep/.el-table__body tr:hover > td {
  background-color: rgba(255, 255, 255, 1) !important;
}
.feed_box {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9;
  .feed_card {
    width: 532px;
    height: 268px;
    background: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    .feed_header {
      width: 100%;
      height: 52px;
      border-bottom: 1px solid #dcdee0;
      .feed_title {
        float: left;
        font-size: 14px;
        font-weight: 500;
        color: #323233;
        line-height: 52px;
        margin-left: 16px;
      }
      .feed_close {
        float: right;
        margin-right: 16px;
        margin-top: 19px;
        cursor: pointer;
      }
    }
    .feed_form {
      width: 463px;
      margin: 0 auto;
      overflow: hidden;
      padding-top: 20px;
      box-sizing: border-box;
      color: #323233;
      line-height: 20px;
      font-size: 14px;
      text-align: center;
      i {
        font-size: 44px;
        color: #185bce;
      }
      div {
        font-size: 14px;
        font-weight: 600;
        color: #323233;
        line-height: 20px;
        margin-bottom: 12px;
      }
      p {
        text-align: left;
      }
    }
    .feed_btn {
      width: 60px;
      height: 32px;
      position: absolute;
      left: 236px;
      bottom: 24px;
      > div {
        width: 60px;
        height: 32px;
        box-sizing: border-box;
        line-height: 30px;
        text-align: center;
        border-radius: 2px;
        cursor: pointer;
      }
      .feed_submit {
        background: #185bce;
        border: 1px solid #185bce;
        color: #ffffff;
      }
    }
  }
}
</style>